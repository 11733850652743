import React from 'react';
import { Card, CardContent, Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const UnableRegistration = ({ setModalOpen }) => {
  return (
    <Card
      sx={{
        padding: 2,
        boxShadow: 3,
        borderRadius: 2,
        textAlign: 'center',
      }}
    >
      <CardContent>
        <Typography
          sx={{
            textAlign: 'justify',
          }}
          variant="h6"
          gutterBottom
        >
          <FormattedMessage id="UnableRegistrationText" />
        </Typography>
        <Button
          variant="contained"
          onClick={() => setModalOpen(false)}
          sx={{
            marginTop: 2,
          }}
        >
          <FormattedMessage id="Close" />
        </Button>
      </CardContent>
    </Card>
  );
};

export default UnableRegistration;
