import React from 'react';
import { Box, Grid2, Link } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import DynamicFontText from '../components/DynamicFontText.jsx';
import amazonAppstoreIcon from '../assets/images/amazon-badge.png';

const MobileApps = () => {
  const amazonStoreLink =
    'https://www.amazon.com/dp/B0DL61MP9H/ref=sr_1_1?sr=8-1';
  // const googlePlayLink =
  //   'https://play.google.com/store/apps/details?id=com.mobile.mnav';

  return (
    <Grid2 container spacing={2} justifyContent="center">
      <Grid2
        item="true"
        size={12}
        sx={{
          textAlign: 'center',
          paddingTop: 2,
        }}
      >
        <DynamicFontText
          text={<FormattedMessage id="MobApps" />}
          fontSize="16px"
        />
      </Grid2>

      <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
        {/* <Link href={googlePlayLink} target="_blank" rel="noopener noreferrer">
          <img
            src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
            alt="Google Play"
            style={{ width: 170, height: 'auto' }}
          />
        </Link> */}

        <Link href={amazonStoreLink} target="_blank" rel="noopener noreferrer">
          <img
            src={amazonAppstoreIcon}
            alt="Amazon Appstore"
            style={{ width: 150, height: 'auto' }}
          />
        </Link>
      </Box>
    </Grid2>
  );
};

export default MobileApps;
