// TODO change id (titles) to lower symbol if the value begins from not capital letter
const en = {
  About: 'About',
  AboutAnyQuestionsTitle:
    'If you have any questions or suggestions, please contact us by email',
  AboutAnyQuestionsDescription:
    'We value your feedback and will endeavor to respond to your message within a reasonable time. Please be aware that due to high volume, a response may take several days. We apologize for any inconvenience and appreciate your understanding',
  AccountDeletionRequest: 'Account Deletion Request',
  Add: 'Add',
  AddItem: 'Add Activity',
  AdditionalInfo: 'Additional Information',
  AddSection: 'Add Group',
  Ago: 'ago',
  Agree: 'By proceeding, you agree to our',
  AlreadyExists: 'Activity already exists',
  And: ' and ',
  AndroidApp: 'Application for Android',
  AppTitle: 'My Activity Navigator',
  Assessment: 'Assessment',
  AssignedDeviceTitle: 'THE DEVICE HAS BEEN ASSIGNED TO EMAIL',
  AssignedDeviceBody1: "You're trying to assign a new user to this device.",
  AssignedDeviceBody2:
    'In this case, ALL information for the previous user with',
  AreYouSure: 'Are you sure?',
  AreYouSureAccountDeletionRequest:
    'Are you sure you want to delete your account? A confirmation email will be sent to your registered email address',
  AutomaticUpdate: 'Automatic update',
  Back: 'Back',
  Basic: 'Basic',
  Benefits: 'Benefits',
  BenefitsEarlyAccess: 'Early Access to New Features',
  BenefitsExchangeDescription:
    'You will be able to easily gather your data from other sources into a single .xlsx file and import the data into the application with just one click',
  BenefitsExchangeTitle: 'Import and Export of Data in Excel Format',
  BenefitsExchangeWarning:
    'During Import, all data from the application will be deleted, so it is necessary to Export and add data to the generated file. It is also not recommended to delete system data that has been entered in the Excel file, as this may lead to errors during Import',
  BenefitsInstantStatisticDescription:
    'For regular users, statistics are updated once a week. For premium users, this limitation is lifted',
  BenefitsInstantStatisticsUpdate: 'Instant Statistics Update',
  BuyCoffee: 'Buy a coffee',
  ByDays: 'By days',
  Cancel: 'Cancel',
  CardNo: 'Card No.',
  CardExpirationDate: 'Expiration date (MM/YY)',
  CardInvalidCode: 'Invalid format, expected 16 digits',
  CardInvalidExpiration: 'Invalid format, expected MM/YY',
  CardInvalidCVV: 'Invalid format, expected 3 digits',
  ChooseFile: 'Choose file',
  Code: 'Code',
  CodeCVV: 'CVV/CVC code',
  CodeIncorrect: 'The entered code is incorrect. Please try again',
  Confirm: 'Confirm',
  ConfirmPassword: 'Confirm Password',
  Contribute: 'Contribute',
  ConsecutiveDays: 'days in a row',
  Count: 'Count',
  CreateProfile: 'Create Your Profile',
  Crossed: 'crossed',
  CurrentWeek: 'Current week',
  DarkTheme: 'Dark',
  day: 'day',
  days: 'days',
  days2: 'days',
  dayAgo: 'yesterday',
  daysAgo: 'days ago',
  DaysAgo: 'Days ago',
  Delete: 'Delete',
  DeleteAccount: 'Delete account',
  Details: 'More',
  Done: 'Done',
  done: 'done',
  DoneConsecutiveDays: 'Done days in a row',
  Download: 'Download',
  EN: 'English',
  ENMobile: 'Eng.',
  Enable: 'Enable',
  EnterCode: 'Enter the code sent to your email',
  Email: 'email',
  EmailAddress: 'Email Address',
  EmailCheck: 'Please check your inbox to set up a new password',
  EmailInUse: 'This email is already in use',
  EmailInvalid: 'Invalid Email Address',
  EmailNotFound: 'The email does not exist',
  Error: 'Error',
  ErrorBody:
    'Service Temporarily Unavailable, possible reasons: maintenance, this part of functionality stop working, no internet',
  Exit: 'Exit',
  Expires: 'expires',
  Expired: 'expired',
  ExpiredLink: 'The link you clicked has expired or is no longer valid',
  Export: 'Export',
  FileHasBeenImported: 'File has been imported successfully',
  Finish: 'Finish',
  FirstDayOfTheWeek: 'First day of the week',
  GetStarted: 'Get Started',
  GoToDate: 'Select Date (dd.mm.yyyy)',
  GoTo: 'Go to Date',
  GroupTitle: 'Title of the Group',
  Home: 'Home',
  Hours: 'hours',
  HiddenItems: 'Hidden activities',
  Import: 'Import',
  IncorrectLoginInfo: 'The username or password is incorrect',
  InCrypto: 'In crypto',
  InitialSettings: 'Default settings',
  ItemDescription: 'Description of the Activity',
  ItemForm: 'Activity Form',
  ItemTargetValue: 'Desired amount per day',
  ItemTitle: 'Title of the Activity',
  Items: 'Activities',
  Language: 'Language',
  LastDate: 'Last Time',
  Latest: 'Latest',
  LatestUpdate: 'Latest Update',
  Lifetime: 'LIFETIME',
  LightTheme: 'Light',
  Loading: 'Loading...',
  LoginPage: 'Login Page',
  Logout: 'Log out',
  MB: 'MB',
  max: 'max',
  maxPerDay: 'max per day',
  maximum: 'maximum',
  Maximum: 'Maximum',
  MaximumPerDay: 'Maximum per day',
  Menu: 'Menu',
  minimum: 'minimum',
  Minimum: 'Minimum',
  MinimumPerDay: 'Minimum per day',
  Minutes: 'minutes',
  Missed: 'Missed',
  MissedConsecutiveDays: 'Missed days in a row',
  Mobile: 'mobile',
  Monday: 'Monday',
  Month: 'Month',
  month: 'month',
  months: 'months',
  months2: 'months',
  More: 'more than',
  moreDaysAgo: 'days ago',
  Negative: 'Negative',
  Next: 'Next',
  NewSection: 'New group',
  No: 'No',
  NoConnection: 'No connection to the server',
  NoDetails: 'Nothing to display',
  NoItems: 'Nothing found',
  None: 'None',
  NoSection: 'Without group',
  Of: 'of',
  Ok: 'Ok',
  OneMoreDeviceDetected: 'Another device has been detected',
  OneMoreDeviceDetectedBody1:
    'Please note that this version supports synchronization with only one device. You can perform the following actions:',
  OpenWebsite: 'Open Web Version',
  Password: 'Password',
  PasswordNotMatching: 'Passwords do not match',
  Payment: 'Payment',
  PerDay: 'per day',
  PerMonth: 'per month',
  PerMonthForDetailsPage: 'per month',
  PleaseWait: 'Please wait...',
  Positive: 'Positive',
  Premium: 'Premium',
  Proceed: 'Proceed',
  ProcessingOrder: 'Your order is being processed',
  Profile: 'Profile',
  PrivacyPolicy: 'Privacy Policy',
  Purchase: 'Purchase',
  records: 'records',
  Refresh: 'Refresh',
  ReleaseDate: 'Release Date',
  RegisterText1_guarantee_delivery:
    'To ensure delivery of our emails, please add',
  RegisterText2_contact_list_spam:
    'to your contact list. This will help you to avoid your e-mails getting into the Spam folder.',
  RegisterText3_After_adding_email:
    "After adding this address and clicking the 'Next' button below on this form, please follow the instructions to complete the registration process.",
  RegisterText4_you_can_login:
    'After registration you will have access to our website as well:',
  RegisterText5_different_approach:
    'The functionality of the website and the application differs, thereby providing a variety of options for completing everyday tasks.',
  RegisterText6_ask_questions: 'Any questions, please contact us via email',
  RegistrationSuccessful:
    'Congratulations, your registration has been successful',
  Remove: 'Remove',
  RemoveSectionAllow: "Are you sure you won't need this group again?",
  RemoveSectionDeny:
    'The group contains linked activities and cannot be deleted at this time',
  RemoveDevice: 'Remove device',
  RemovedItem: 'The Activity has been removed successfully',
  ResetPassword: 'Reset Password',
  ResetPasswordForm: 'Reset Password Form',
  RequiredField: 'Required Field',
  RU: 'Russian',
  RUMobile: 'Rus.',
  Saturday: 'Saturday',
  Save: 'Save',
  ScheduledUpdate: 'Scheduled Update',
  Seconds: 'seconds',
  Section: 'Group',
  Sections: 'Groups',
  SelectDate: 'Select date',
  SelectExisting: 'Or select existing value',
  Settings: 'Settings',
  SignIn: 'Sign In',
  Size: 'Size',
  Synchronization: 'Synchronization',
  Synchronize: 'Synchronize',
  Synchronized: 'Synchronized',
  SyncDate: 'Sync date',
  SyncEraseAllData:
    'Synchronizing with a new device will erase all existing data on the device',
  SyncEraseExistingData:
    'Local data on the device will be deleted when synchronization is enabled',
  SyncProcess:
    'Syncing with the mobile device is in progress, this should not take long',
  SyncReqTitle: 'Synchronization required',
  SyncNeedErrorBody:
    'For data security reasons, the website cannot be used until synchronization with your mobile device is complete. Please start or restart the application on your mobile device and wait for synchronization to finish. If synchronization is not necessary, you can deactivate it using the "Remove device" button',
  SyncNeedsToBeDone:
    'Synchronization needs to be completed before using the app',
  SyncToMobileTitle: 'Synchronization with Mobile Device',
  SyncToServerTitle: 'Synchronization with Website',
  SpamFolder:
    "If you don't receive the email within a certain timeframe, please check the 'Spam' folder",
  Started: 'Started',
  Statistics: 'Statistics',
  status: 'status',
  Sunday: 'Sunday',
  Syncing: 'Syncing',
  SyncFromServerToDevice: 'synchronization from the server to this device',
  Switch: 'Switch',
  target: 'target',
  TargetAsNumber: 'Target must be a number',
  TargetDone: 'Target achieved',
  TargetNotDone: 'Target not achieved',
  TermsOfService: 'Terms of Service',
  Theme: 'Theme',
  TitleLength: 'Title exceeds maximum length',
  Today: 'Today',
  Total: 'Total',
  TryAgain: 'Try Again',
  TurnOffSync: 'Disable synchronization',
  Type: 'Type',
  TypeValueConsistent: 'Type value must be consistent',
  UA: 'Ukrainian',
  UAMobile: 'Ukr.',
  UpdateNow: 'Update Now',
  Upgrade: 'Upgrade',
  Unlock: 'Unlock',
  Use: 'Use',
  UseDeviceWithoutSync: 'this device without synchronization',
  ValidationStage: 'Validation Stage',
  Values: 'Values',
  VerifiedBy: 'Verified by',
  Warning: 'Warning!',
  Website: 'website',
  Week: 'Week',
  WillBeRemoved: 'will be removed',
  Year: 'Year',
  year: 'year',
  years: 'years',
  years2: 'years',
  YesDelete: 'Yes, delete',
  minutes: 'minutes',
  number: 'number',
  seconds: 'seconds',
  text: 'text',
  Close: 'Close',
  UpdateVersionTitle: 'Please update to a new version',
  WhatsNew: "What's new?",
  Version: 'Version',
  AvailableInPremium: 'Available in premium',
  GoToMobDate: 'Go to Date',
  SortByActivity: 'Sorting by activity',
  Top: 'Top',
  Bottom: 'At the bottom',
  step: 'step',
  Reset: 'Reset',
  EnterSomeTitle: 'Enter your text',
  NothingFoundOrUpdating: 'Nothing found or data is being updated',
  MobApps: 'Mobile App',
  UnableRegistrationText:
    'Registration is temporarily closed. Check back soon for new opportunities and exciting surprises. Thank you for your understanding!',
  DetailedStatistics: 'Detailed statistics',
  Records: 'Records',
  DailyRecords: 'Daily records',
  Sound: 'Sound',
  On: 'On',
  Off: 'Off',
  FirstLoading: 'Processing data, please wait...',
};

export default en;
