/* eslint-disable no-undef */
/* eslint-disable */
import React, { useContext, useState } from 'react';
import {
  Grid2,
  FormLabel,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Copyright from '../components/Copyright.jsx';
import Loader from '../components/Loader.jsx';
import Title from '../components/Title.jsx';
import DynamicFontText from '../components/DynamicFontText.jsx';
import TwoButtons from '../components/TwoButtons.jsx';
import { AppContext } from '../context/app.js';
import css from '../themes/styles.js';
// import AndroidApp from '../components/AndroidApp.jsx';
// import ReleaseNotes from './ReleaseNotes.jsx';
import MobileApps from '../components/MobileApps.jsx';
import UnableRegistration from '../components/UnableRegistration.jsx';

const Home = () => {
  const disableRegistration = localStorage.getItem('disableRegistration') === 'true';
  const { themeTitle, toggleTheme, locale, toggleLocale } =
    useContext(AppContext);  
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [selectedLocale, setSelectedLocale] = useState(locale);
  const [unableRegModalOpen, setUnableRegModalOpen] = useState(false);

  const handleToggleChange = (locale) => {
    toggleLocale(locale);
    setSelectedLocale(locale);
  };

  React.useEffect(() => {
    async function fetchData() {
      try {
        setLoading(false);
      } catch (error) {
        setLoading(false);
        navigate(`/error`);
      }
    }
    fetchData();
  }, []);

  if (loading) return <Loader />;

  return (
    <Grid2 container spacing={2} justifyContent="center" >
      <Grid2 item="true" size={12}>
        <Title text={<FormattedMessage id="AppTitle" />} />
      </Grid2>

      {unableRegModalOpen ? (
        <>
          <Grid2 item="true" size={2} />
          <Grid2 item="true" size={8}>
            <UnableRegistration setModalOpen={setUnableRegModalOpen} />
          </Grid2>
          <Grid2 item="true" size={2} sx={{ height: '10vh' }} />
        </>
        ) : (
        <>
          <Grid2 item="true" size={3} />
          <Grid2 item="true" size={6}>
            <TwoButtons
              leftTitle={<FormattedMessage id="GetStarted" />}
              handleLeftClick={() => {
                if (!disableRegistration) navigate('/register');
                else {
                  setUnableRegModalOpen(true);
                }
              } }
              rightTitle={<FormattedMessage id="SignIn" />}
              handleRightClick={() => {
                navigate('/login');
              } } />
          </Grid2>
          <Grid2 item="true" size={3} sx={{ height: '10vh' }} />
        </>
    )}
      <Grid2 item="true" size={12} sx={{ textAlign: 'center' }}>
        <DynamicFontText
          text={<FormattedMessage id="InitialSettings" />}
          fontSize="16px"
        />
      </Grid2>
      <Grid2 item="true" size={3} />
      <Grid2 item="true" size={3} sx={{ ...css.gridRight }}>
        <FormControl>
          <FormLabel id="theme-radio-buttons">
            <FormattedMessage id="Theme" />
          </FormLabel>
          <RadioGroup
            aria-labelledby="theme-radio-buttons"
            value={themeTitle}
            name="radio-buttons-group"
            row
          >
            <FormControlLabel
              value="darkTheme"
              control={<Radio onChange={() => toggleTheme('darkTheme')} />}
              label={<FormattedMessage id="DarkTheme" />}
            />
            <FormControlLabel
              value="lightTheme"
              control={<Radio onChange={() => toggleTheme('lightTheme')} />}
              label={<FormattedMessage id="LightTheme" />}
            />
          </RadioGroup>
        </FormControl>
      </Grid2>
      <Grid2 item="true" size={3}>
        <FormControl>
          <FormLabel id="language-radio-buttons">
            <FormattedMessage id="Language" />
          </FormLabel>
          <RadioGroup
            aria-labelledby="language-radio-buttons"
            value={locale}
            name="radio-buttons-group"
            row
          >
            <FormControlLabel
              value="en"
              control={<Radio onChange={() => handleToggleChange('en')} />}
              label={<FormattedMessage id="EN" />}
            />
            <FormControlLabel
              value="ru"
              control={<Radio onChange={() => handleToggleChange('ru')} />}
              label={<FormattedMessage id="RU" />}
            />
            <FormControlLabel
              value="uk"
              control={<Radio onChange={() => handleToggleChange('uk')} />}
              label={<FormattedMessage id="UA" />}
            />
          </RadioGroup>
        </FormControl>
      </Grid2>
      <Grid2 item="true" size={3}/>

      <MobileApps />
      {/* <Grid2 item="true" size={1}/>
      <Grid2 item="true" size={10}>
        <ReleaseNotes incomingLocale={selectedLocale} />
      </Grid2>
      <Grid2 item="true" size={1}/> */}

      {/* <AndroidApp /> */}
      <Grid2 item="true" size={12} sx={{ height: '3vh' }} />
      <Grid2 item="true" size={12}>
        <Copyright />
      </Grid2>
    </Grid2>
  );
};

export default Home;
