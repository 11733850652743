/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import Router from './routers/Router';

import { AuthContext } from './context/auth.js';
import { UserContext } from './context/user.js';
import { fetchApiMethods } from './api/getMethods.js';
import SyncProcess from './pages/SyncProcess.jsx';

const App = () => {
  const [autoSchedule, setAutoSchedule] = useState(false);
  const [api, setApi] = useState({});
  const [date, setDate] = useState(new Date());
  const [id, setId] = useState();
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [synced, setSynced] = useState(true);

  const fetchData = async () => {
    const api = await fetchApiMethods();
    setApi(api);

    const email = localStorage.getItem('email');
    const settingsResponse = await api.settings.getAll({});
    const { adminEmail, disableRegistration } = settingsResponse.settings;
    localStorage.setItem('adminEmail', adminEmail);
    localStorage.setItem('disableRegistration', disableRegistration);

    if (!email) setIsAuth(false);
    else {
      const { user } = await api.user.find({ email });
      if (!user) {
        // TODO Improve the code
        localStorage.removeItem('token');
        localStorage.removeItem('email');
        localStorage.removeItem('selectedTheme');
        localStorage.removeItem('selectedLocale');
        localStorage.removeItem('selectedDefaultWeekDay');
        localStorage.removeItem('item');
        setIsAuth(false);
      }
    }

    const apiResponse = await api.system.getVersion();
    const version = localStorage.getItem('apiVersion');
    if (!version || apiResponse.version !== version) {
      localStorage.setItem('apiVersion', apiResponse.version);
      localStorage.removeItem('token');
    }
    if (localStorage.getItem('token')) {
      setIsAuth(true);
      const { syncInfo } = await api.sync.getInfo();
      if (syncInfo?.syncProcess) setSynced(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // HANDLERS SECTION
  const handleRefreshClick = async () => {
    if (isAuth) {
      const { syncInfo } = await api.sync.getInfo();
      if (syncInfo.syncProcess) setSynced(false);
      else setSynced(true);
    }
  };
  const handleRemoveButtonClick = async () => {
    await api.user.update({
      deviceId: null,
      removedDeviceId: null,
      syncToServer: false,
      syncToMob: false,
      syncProcess: false,
      turnOffSync: true,
    });
    setSynced(true);
  };

  // RETURN SECTION
  if (!synced && isAuth)
    return (
      <SyncProcess
        handleButtonClick={handleRemoveButtonClick}
        handleRefreshClick={handleRefreshClick}
      />
    );

  return (
    <AuthContext.Provider value={{ isAuth, setIsAuth, loading }}>
      <UserContext.Provider
        value={{
          id,
          setId,
          date,
          setDate,
          autoSchedule,
          setAutoSchedule,
        }}
      >
        <div>
          <Router setSynced={setSynced} />
        </div>
      </UserContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;
